import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      mixinsUseRequest_data: 'mixinsUseRequest/getData',
    }),
  },
  methods: {
    ...mapMutations({
      $_setData: 'mixinsUseRequest/setData',
      $_reset: 'mixinsUseRequest/reset',
    }),
    async mixinsUseRequest_useRequest(
      key,
      fnPromise,
    ) {
      this.$_setData({ key, props: 'isLoading', data: true })
      try {
        const res = await fnPromise()
        this.$_setData({ key, props: 'isSuccess', data: true })
        this.$_setData({ key, props: 'isError', data: false })
        this.$_setData({ key, props: 'data', data: res || null })
        return { data: res }
      } catch (e) {
        this.$_setData({ key, props: 'isSuccess', data: false })
        this.$_setData({ key, props: 'isError', data: true })
        return { error: e }
      } finally {
        this.$_setData({ key, props: 'isLoading', data: false })
      }
    },
  },
  created() {
    if (this.$options.mixinsUseRequest_registerKey) {
      for (const key of this.$options.mixinsUseRequest_registerKey) {
        this.$_setData({ key, props: 'isLoading', data: false })
      }
    }
  },
  beforeDestroy() {
    if (this.$options.mixinsUseRequest_registerKey) {
      for (const key of this.$options.mixinsUseRequest_registerKey) {
        this.$_reset({ key })
      }
    }
  },
}
